/* Call to Action Element */
// 1. Default Styles
// 2. Variations
// 3. Edge Case Fixes
// 4. Fix for tall text in short container
// 5. Adjustments for Small Columns & Mobile Views
// 6. Accomidation for touch screens

// 1. Default Styles
.sn-call-to-action,
.sn-call-to-action * {
  box-sizing: border-box;
}
.sn-call-to-action {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: $breakpoint-sm-max) {
    height: 150px !important;
  }
  &:hover {
    &:before {
      opacity: 0.5;
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    background: $color-primary;
    opacity: 0.2;
    z-index: 1;
    transition: opacity $transition-linear;
  }
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }
  .sn-call-to-action-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 1;
    text-align: center;
  }
  .sn-call-to-action-overlay-inner {
    display: table;
    width: 100%;
    height: 100%;
  }
  .sn-call-to-action-overlay-text {
    display: table-cell;
    font-size: rem(10);
    height: 100%;
    line-height: 1;
    vertical-align: middle;
  }
  .sn-call-to-action-title {
    color: #fff;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0.3em;
    z-index: 2;
  }
  .sn-call-to-action-subtitle {
    color: #fff;
    font-family: $font-secondary;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 0;
    a,
    span {
      margin: 0 .25em;
      color: #fff;
      display: inline-block;
    }
  }
}
.sn-call-to-action .sn-call-to-action-subtitle:first-of-type > * {
  padding-top: 5px;
}

// 2. Variations

/* Slide Up */
.sn-call-to-action.sn-variation-slide-up {
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    display: block;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.85) 100%);
    z-index: 1;
  }
  .sn-call-to-action-overlay {
    top: auto;
    height: 0;
    transition: height 0.5s ease;
  }
  .sn-call-to-action-title {
    margin-bottom: 20px;
    &:after {
      content: '';
      height: 5px;
      width: 70px;
      background-color: #fff;
      display: block;
      margin: 5px auto;
      opacity: 0;
      transition: opacity $transition-linear;
    }
  }
  .sn-call-to-action-subtitle {
    opacity: 0;
    margin-bottom: 10px;
    transition: opacity 0.5s ease, color $transition-linear;
    a {
      transition: color $transition-linear;
    }
    &:hover {
      a {
        color: $color-secondary;
      }
    }
  }
  &:hover {
    .sn-call-to-action-overlay {
      height: 100%;
      background: rgba(25,28,34,0.5);
    }
    .sn-call-to-action-title {
      &:after {
        content: '';
        height: 5px;
        width: 70px;
        background-color: #fff;
        display: block;
        margin: 5px auto;
        opacity: 1;
      }
    }
    .sn-call-to-action-subtitle {
      opacity: 1;
    }
  }
}

/* Zoom */
.sn-call-to-action.sn-variation-zoom {
  img {
    transform: scale(1);
    transition: transform 0.5s ease;
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}

/* Text Zoom */
.sn-call-to-action.sn-variation-text-zoom {
  .sn-call-to-action-overlay-text {
    transform: scale(1);
    transition: transform 0.5s ease;
  }
  &:hover {
    .sn-call-to-action-overlay-text {
      transform: scale(1.1);
    }
  }
}

/* Border */
.sn-call-to-action.sn-variation-border {
  .sn-call-to-action-overlay {
    box-shadow: inset 0 0 0 0 transparent, inset 0 0 0 0 transparent;
    transition: box-shadow 0.5s ease-in;
  }
  &:hover {
    .sn-call-to-action-overlay {
      box-shadow: inset 0 7px 0 10px $link-color_opacity, inset 0 -5px 0 10px $link-color_opacity;
    }
  }
}

// 3. Edge Case Fixes

/* mirrored CTA preview img alignment fixes */
.pe25 .elementPreview img,
.pe33 .elementPreview img {
  width: 100%;
  margin: 0px;
}

// 4. Fix for tall text in short container
.sn-call-to-action.sn-variation-tall-text {
  img {
    position: absolute;
    top: 50%;
    left: 20%;
    width: auto;
    max-width: initial;
    min-width: 100%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: $breakpoint-sm-max) {
      height: 150px !important;
    }
  }
}
.sn-call-to-action.sn-variation-zoom.sn-variation-tall-text {
  &:hover {
    img {
      transform: scale(1.2) translate(-40%, -40%);
    }
  }
}

// 5. Adjustments for Small Columns & Mobile Views

/* paragraph spacing */
.pe25 .sn-call-to-action-overlay-text p {
  margin-bottom: .15em;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .col-md-4 .sn-call-to-action, .col-md-3 .sn-call-to-action{
    .sn-call-to-action-title{
      font-size: rem(20);
    }
    .sn-call-to-action-subtitle{
      font-size: rem(14);
    }
  }
}
@media only screen and (max-width: 350px) {
  .sn-call-to-action{
    .sn-call-to-action-title{
      font-size: rem(20);
    }
    .sn-call-to-action-subtitle{
      font-size: rem(14);
    }
  }
}

// 6. Accomodation for touch screens
.touch-screen {
  .sn-call-to-action.sn-variation-slide-up,
  .sn-call-to-action.sn-variation-slide-up:focus,
  .sn-call-to-action.sn-variation-slide-up:hover{
    .sn-call-to-action-overlay {
      height: 100%;
      transition: none;
    }
    .sn-call-to-action-subtitle {
      opacity: 1;
      transition: none;
    }
  }
  .sn-call-to-action.sn-variation-border,
  .sn-call-to-action.sn-variation-border:focus,
  .sn-call-to-action.sn-variation-border:hover{
    .sn-call-to-action-overlay{
      transition: none;
      box-shadow: inset 0 7px 0 10px $link-color_opacity, inset 0 -5px 0 10px $link-color_opacity;
    }
  }
  .sn-call-to-action.sn-variation-text-zoom,
  .sn-call-to-action.sn-variation-text-zoom:focus,
  .sn-call-to-action.sn-variation-text-zoom:hover{
    .sn-call-to-action-overlay-text{
      transition: none;
      transform: scale(1);
    }
  }
}

// 7. Home Page CTAs - Removing Padding/Margin
.cta-container {
  padding-left: 0;
  padding-right: 0;
  .pageElement {
    margin-top: 1px;
    margin-bottom: 1px;
    .sn-call-to-action {
      @media screen and (max-width: $breakpoint-sm-max) {
        height: 60px !important;
        background: $color-black;
        img {
          display: none;
        }
        .sn-call-to-action-overlay {
          .sn-call-to-action-title {
            margin-bottom: 0;
            &:after {
              display: none;
            }
          }
          .sn-call-to-action-subtitle {
            display: none;
          }
        }
      }
    }
  }
  .column {
    border:0;
    border-right: 1px solid #fff;
  }
  .column-1 {
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width: $breakpoint-sm-max) {
      border-right: 0;
    }
  }
  .column-2 {
    padding-left: 0;
    padding-right: 0;
  }
  .column-3 {
    border-right: 1px solid #fff;
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width: $breakpoint-sm-max) {
      border-left: 0;
    }
  }
}
